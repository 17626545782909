import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "../../services/emailValidate";
import validator from "validator";
import Swal from "sweetalert2";
import api from "../../services/api";
export default function RecoverPassword() {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (formData.email < 2 || formData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: "Please enter your email" };
      valid = false;
    }
    if (!emailValidate(formData.email)) {
      errorsTmp = { ...errorsTmp, email: "Your email not valid" };
      valid = false;
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const [acceptField, setAcceptField] = useState({
    email: false,
  });

  const formInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: "Your email not valid" });
      }
    }

    updateFormData(field, value);
  };
  const [formData, setFormData] = useState({
    email: "",
  });

  const sendAction = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();
    if (valid) {
      var response = await api.post("/request-reset-password", formData);
      // console.log(response);
      if (response.status == 200) {
        // setRegistrationSuccessModal(true);
        Swal.fire({
          title: "Email Sent!",
          text: "A password recovery email has been sent to your provided email address.",
          icon: "success",
        });
      } else {
        // setAlertMsg({
        //   message: response.data.message,
        //   variant: "danger",
        //   showAlert: true,
        // });

        // setTimeout(() => {
        //   setAlertMsg({
        //     message: response.data.message,
        //     variant: "success",
        //     showAlert: false,
        //   });
        // }, 7000);

        Swal.fire({
          title: "Warning!",
          text: response.data.message,
          icon: "warning",
        });

      }

      // Swal.fire({
      //   title: "Done!",
      //   text: "instructions has been sent to your email.",
      //   icon: "success",
      // });
      // history.push("/login");
    }
  };

  // console.log("Data", formData);
  return (
    <>
      <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
        {alertMsg.message}
      </Alert>
      <div className="login_page">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="login_headline">
                <span className="golden">WELCOME</span> <br />
                BACK.
              </div>
              <br />
              <div className="paragraph white">
                Get ready for some serious fun and <br />
                let the good times roll across any device
              </div>
              <br />
              <br />
              <br />
              <Link className="common_button uppercase" to="/register">
                Create Account
              </Link>
            </div>
            <div className="col-md-6">
              <div className="register no_padding col-md-9">
                <div className="form_area login_area">
                  <div className="form_title small">Forgot Your Password?</div>
                  <br />
                  <div className="other_link_area paragraph">
                    Enter your registered email address below and password reset
                    instructions will be sent to you.
                  </div>

                  <br />
                  <div className="indicatior"></div>
                  <br />
                  <form onSubmit={sendAction}>
                    <div className="input_item">
                      <div className="name reg_field">Email</div>
                      <input
                        onChange={formInputChange}
                        type="email"
                        name="email"
                        className={
                          acceptField.email
                            ? "form-control name_value border_success"
                            : "form-control name_value"
                        }
                        placeholder="Your email"
                        value={formData.email}
                      />
                      <div className="error_msg">{errors.email}</div>
                    </div>
                    <div className="login_button">
                      <Button type="submit" className="login_btn gradiant">
                        Send
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
