// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://wildworldcasino.com",
    apiUrl: "https://api.wildworldcasino.com/api/v1",
    staticUrl: "https://api.wildworldcasino.com/api/v1",
    liveChatUrl: "https://bossoffice.wildworldcasino.com/public/livechat/php/app.php?widget-inline",
    FBAppId: "523317649620043",
    GoogleClientId: "477163522509-gj1eq4tt5oj0vq2hut66spsvhgd51oq2.apps.googleusercontent.com",
    debug: false,
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://wwc.cbstagings.com",
    apiUrl: "https://wwcapi.cbstagings.com/api/v1",
    staticUrl: "https://wwcapi.cbstagings.com/api/v1",
    publicDir: "https://wwcboss.cbstagings.com/public",
    liveChatUrl: "https://wwcboss.cbstagings.com/public/livechat/php/app.php?widget-inline",
    FBAppId: "1172568450039492",
    GoogleClientId: "790397384946-mk49403chl2iojqcvin3a303kt2umult.apps.googleusercontent.com",
    debug: false,
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://casinoapi.local.host/api/v1",
    staticUrl: "http://casinoapi.local.host/api/v1",
    liveChatUrl: "http://casinoadmin.local.host/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://casinoadmin.local.host/public",
    debug: true,
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;

// export default config[configEnv];
