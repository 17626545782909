import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import emailValidate from "../../services/emailValidate";
import validator from "validator";
import Swal from "sweetalert2";
import api from "../../services/api";

export default function ResetPassword() {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully updated",
    variant: "success",
    showAlert: false,
  });

  var params = useParams();

  const [changePasswordForm, setChangePasswordForm] = useState({
    user_id: params.userId,
    code: params.code,
    password: "",
    confirm_password: "",
  });

  const validateForm = () => {
    var valid = true;

    var errorsTmp = {};

    if (
      validator.isStrongPassword(changePasswordForm.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
    } else {
      errorsTmp = {
        ...errorsTmp,
        password:
          "Your password must contain between 8 and 30 characters, including at least one number, one special character, one lowercase and one upercase. Do not use your email address or username as your password.",
      };
      valid = false;
    }

    if (changePasswordForm.password.length < 8 || changePasswordForm.password.length > 30) {
      errorsTmp = {
        ...errorsTmp,
        password: "Your password must contain between 8 and 30 characters",
      };
      valid = false;
    }

    if (changePasswordForm.password != changePasswordForm.confirm_password) {
      errorsTmp = { ...errorsTmp, confirm_password: "Password does not match" };
      valid = false;
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateFormData = (field, value) => {
    setChangePasswordForm({ ...changePasswordForm, [field]: value });
  };

  const [acceptField, setAcceptField] = useState({
    password: false,
    confirm_password: false,
  });

  const formInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            "Password at least 8-30 characters, 1 number, 1 special character, 1 lowercase and one upercase. Don't use email address or username.",
        });
      }
    }
    if (field == "confirm_password") {
      var password = changePasswordForm.password;
      if (value === password) {
        setAcceptField({ ...acceptField, confirm_password: true });
        setErrors({ ...errors, confirm_password: "" });
      } else {
        setAcceptField({ ...acceptField, confirm_password: false });
        setErrors({ ...errors, confirm_password: "password doesn't match" });
      }
    }

    updateFormData(field, value);
  };

  const resetPasswordAction = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();

    if (valid) {
      var response = await api.post("/reset-password-action", changePasswordForm);

      if (response.status == 200) {
        Swal.fire({
          title: response.data.message,
          icon: "success",
        });

        history.push("/login");
      } else {
        if (response.data.errors) {
          setErrors(response.data.errors);
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
          });
        }
      }
    }
  };

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 offset-1 offset-col-1 offset-sm-1 offset-md-2 offset-lg-4">
            <div className="form_area">
              <div className="common_headline text-center">Set your password</div>
              <br />
              <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                {alertMsg.message}
              </Alert>
              <form onSubmit={resetPasswordAction}>
                <div className="input_item">
                  <div className="name reg_field">New password</div>
                  <input
                    onChange={formInputChange}
                    type="password"
                    name="password"
                    className={
                      acceptField.password ? "form-control name_value border_success" : "form-control name_value"
                    }
                    placeholder="new password"
                    value={changePasswordForm.password}
                  />
                  <div className="error_msg">{errors.password}</div>
                </div>
                <div className="input_item">
                  <div className="name reg_field">Confirm new password</div>
                  <input
                    onChange={formInputChange}
                    type="password"
                    name="confirm_password"
                    className={
                      acceptField.confirm_password
                        ? "form-control name_value border_success"
                        : "form-control name_value"
                    }
                    placeholder="Confirm new password"
                    value={changePasswordForm.confirm_password}
                  />
                  <div className="error_msg">{errors.confirm_password}</div>
                </div>
                <div className="login_button">
                  <Button type="submit" className="login_btn">
                    Save Change
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
