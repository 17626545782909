import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";

import api from "../services/api";
import auth from "../services/auth";
import AppContext from "../contexts/AppContext";
import emailValidate from "../services/emailValidate";
import ls from "local-storage";
import moneyFormatter from "services/moneyFormatter";

import CategoryGames from "../elements/CategoryGames";
// import CategoryGames from "../components/ImageGalary";
import Banner from "elements/Banner";
import ImageGalary from "../components/ImageGalary";
import ReviewSection from "elements/ReviewSection";
import GameMenu from "elements/GameMenu";
import RecentlyPlayedGames from "elements/RecentlyPlayedGames";
import Swal from "sweetalert2";
export default function Landing(props) {
  const [seconds, setSeconds] = useState(4083958);
  const params = useParams();
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60);
    return () => clearInterval(interval);
  }, []);

  const [categoryName, setCategoryName] = useState("Slots");
  const __ = (text) => text;

  useEffect(async () => {
    if (params.status == "true") {
      Swal.fire({
        title: "Success!",
        text: "Successfully bought coins package.",
        icon: "success",
      });
    } else if (params.status == "false") {
      Swal.fire({
        title: "Warning!",
        text: "Buying coins package failed!",
        icon: "warning",
      });
    }
  }, [params.status]);

  return (
    <>
      <Banner {...props} />

      <section className="online_games">
        <div className="container">
          <div className="common_heading text-center">
            EXCITING ONLINE <br />
            CASINO-STYLE GAMES
          </div>
          <div className="common_title text-center">
            With over 680 games to choose from, there is always
            <br /> something new to play.
          </div>
          <div className="filter_game d-none">
            <ul>
              <li>
                <Link
                  className={
                    "common_button in_game_filter " +
                    (categoryName == "Slots" ? "active" : "")
                  }
                  onClick={() => {
                    setCategoryName("Slots");
                  }}
                >
                  SLOTS{" "}
                </Link>
              </li>
              <li>
                <Link
                  className={
                    "common_button in_game_filter " +
                    (categoryName == "Jackpots" ? "active" : "")
                  }
                  onClick={() => {
                    setCategoryName("Jackpots");
                  }}
                >
                  {" "}
                  JACKPOTS
                </Link>
              </li>
              <li>
                <Link
                  className={
                    "common_button in_game_filter " +
                    (categoryName == "Table Games" ? "active" : "")
                  }
                  onClick={() => {
                    setCategoryName("Table Games");
                  }}
                >
                  {" "}
                  TABLE GAMES
                </Link>
              </li>
              <li>
                <Link
                  className={
                    "common_button in_game_filter " +
                    (categoryName == "All Games" ? "active" : "")
                  }
                  onClick={() => {
                    setCategoryName("All Games");
                  }}
                >
                  {" "}
                  ALL GAMES
                </Link>
              </li>
              <li>
                <Link
                  className={
                    "common_button in_game_filter " +
                    (categoryName == "New Games" ? "active" : "")
                  }
                  onClick={() => {
                    setCategoryName("New Games");
                  }}
                >
                  {" "}
                  New Games
                </Link>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
        <GameMenu {...props} />
        <div className="container">
          <br />
          <RecentlyPlayedGames {...props} />
          <CategoryGames perPage="60" title="" {...props} />
        </div>
      </section>

      <section className="services service-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="services_headline"> Over 10,000 players</div>
              <div className="services_text">
                With the chance to win prizes in exciting online casino-style
                games, more and more customers are choosing Wild World Casino
                each day.
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_headline">Fastest Checkouts</div>
              <div className="services_text">
                We accept fast and secure payments from all the leading payment
                providers without additional fees.
                <div className="row d-none">
                  <div className="col-2">
                    {moneyFormatter.currencyIcon("BTC")}
                  </div>
                  <div className="col-2">
                    {moneyFormatter.currencyIcon("ETH")}
                  </div>
                  <div className="col-2">
                    {moneyFormatter.currencyIcon("LTC")}
                  </div>
                  <div className="col-2">
                    {moneyFormatter.currencyIcon("DOGE")}
                  </div>
                  <div className="col-2">
                    {moneyFormatter.currencyIcon("USDTE")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_headline">24/7 Customer Service</div>
              <div className="services_text">
                If you can't find the answer to your question you can always get
                in touch with our Support team.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fireshot">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-12">
              <h3 className="common_heading text-center fireshot_headline_title">
                The Most Exciting Games!
              </h3>
              <div className="fireshot_desctiption">
                The excitement increases with two slots games sharing <br /> the
                same progressive jackpot.
              </div>
              <div className="fireshot_game">
                <ImageGalary />
              </div>
              <div className="button_section">
                <Link className="common_button" to="/home">
                  Play now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="review d-none">
        <div className="container">
          <div className="common_heading text-center">
            EXCITING ONLINE <br />
            CASINO-STYLE GAMES
          </div>
          <div className="common_title text-center">
            With over 680 games to choose from, there is always something new to
            play.
          </div>
          <div className="reviews">
            <div className="row">
              <div className="col-md-4">
                <div className="review-shapeBox">
                  <div className="single_review">
                    <div className="review_content">
                      <span className="quote left-quote">“</span>Games are
                      awesome, redemptions are the quickest I have ever seen.
                      <span className="quote right-quote">”</span>
                    </div>
                    <div className="review_user">
                      <div className="username"> Larry H.</div>
                      <div className="porition">
                        Unsolicited testimonial from
                      </div>
                      <div className="porition">Indiana USA</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="review-shapeBox middle-boxRVW">
                  <div className="single_review">
                    <div className="review_content">
                      <span className="quote left-quote">“</span>I absolutely
                      love Wild World Casino! I have won big and small, never
                      had issues with redeeming my wins. Their customer service
                      is A+ and has never left me with an unanswered question.
                      <span className="quote right-quote">”</span>
                    </div>
                    <div className="review_user">
                      <div className="username"> Angel Hall</div>
                      <div className="porition">
                        Unsolicited testimonial from
                      </div>
                      <div className="porition">USA</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="review-shapeBox">
                  <div className="single_review">
                    <div className="review_content">
                      <span className="quote left-quote">“</span>Thank you for
                      all the support through customer service. Very helpful and
                      patient with me.
                      <span className="quote right-quote">”</span>
                    </div>
                    <div className="review_user">
                      <div className="username"> Jason M.</div>
                      <div className="porition">
                        Unsolicited testimonial from
                      </div>
                      <div className="porition">Oklahoma USA</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="button_section">
              <Link className="common_button with_review_icon" to="#">
                read more at{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1133 278"
                  height="1em"
                >
                  <path
                    fill="#fff"
                    d="M298 99h114v21h-45v120h-25V120h-44V99zm109 39h22v19l4-8a36 36 0 0116-12l10-2h9v22a72 72 0 00-10-1c-4 0-8 1-11 3l-9 6-6 11c-2 4-2 10-2 15v49h-23V138zm165 102h-23v-14c-3 5-7 9-13 12-5 4-11 5-16 5-14 0-24-3-30-10s-9-17-9-30v-65h23v63c0 9 2 15 5 19 4 3 8 5 15 5 5 0 8 0 12-2 3-1 5-3 7-6 2-2 4-5 5-9l1-11v-59h22v102zm38-33c1 7 3 12 8 14 4 3 9 4 15 4h7l8-2 6-4 2-7c0-3-1-6-3-7l-8-5-10-3-12-2-13-4-10-5c-3-2-6-5-8-9-2-3-2-8-2-13s1-10 4-14c2-4 6-7 10-9l14-5 14-1c6 0 11 0 16 2a32 32 0 0128 30h-24c-1-6-4-10-8-11-3-2-8-3-13-3a42 42 0 00-12 2c-2 0-3 1-5 3l-2 5c0 3 1 5 3 7l8 4 10 3 13 3 12 3c4 2 7 3 10 6l8 8c2 4 3 8 3 13 0 6-2 11-5 15-2 5-6 8-10 11l-15 5a74 74 0 01-34 0c-6-1-11-4-15-7a33 33 0 01-13-26h23zm75-69h17v-31h22v31h21v17h-21v54l1 7 1 4 3 3 6 1 5-1h5v17l-7 1a58 58 0 01-22-1c-4-1-7-3-9-5l-4-8-1-12v-60h-17v-17zm76 0h21v14h1c3-6 7-11 13-13 5-3 12-4 18-4 8 0 15 1 21 4s11 7 15 12 7 11 9 17a73 73 0 01-8 59 40 40 0 01-33 16 55 55 0 01-19-4 34 34 0 01-15-12h-1v51h-22V138zm79 51l-2-13-5-12-9-8c-4-2-8-3-13-3-9 0-16 3-21 10-5 6-7 15-7 26 0 5 0 10 2 15l5 11c3 3 6 6 9 7l12 3a25 25 0 0022-11c3-4 4-7 5-12l2-13zm40-90h22v21h-22V99zm0 39h22v102h-22V138zm42-39h23v141h-23V99zm92 144c-8 0-15-1-22-4-6-3-11-6-16-11-4-5-8-11-10-17a66 66 0 010-44c2-6 6-12 10-17 5-5 10-8 16-11 7-3 14-4 22-4s16 1 22 4c7 3 12 7 17 11l10 17c2 7 3 14 3 22s-1 15-3 22c-3 6-6 12-11 17-4 5-9 8-16 11-6 3-13 4-22 4zm0-18a26 26 0 0022-11l6-12a53 53 0 000-26c-1-5-3-8-6-12a26 26 0 00-22-11 26 26 0 00-22 11l-5 12a54 54 0 000 26l5 12c2 3 5 6 9 8s8 3 13 3zm59-87h17v-31h22v31h21v17h-21v54l1 7 1 4 3 3 6 1 5-1h5v17l-7 1a58 58 0 01-22-1c-4-1-7-3-9-5l-4-8-1-12v-60h-17v-17z"
                  ></path>
                  <path
                    fill="#00b67a"
                    d="M271 99H168L136 0l-32 99H0l84 61-32 98 84-61 83 61-31-98 83-61z"
                  ></path>
                  <path fill="#005128" d="M195 182l-8-22-51 37z"></path>
                </svg>
              </Link>
            </div> */}
          </div>
        </div>
      </section>

      <ReviewSection />

      <section className="login_page no_purchase_area ">
        {/* <img
          alt=""
          src={require("../assets/images/icons/banner-with-wheel.png").default}
        /> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner_text_section">
                <div className="login_headline">
                  <span className="golden">NO PURCHASE</span> <br />
                  NECESSARY.
                </div>

                <div className="paragraph white">
                  <br />
                  It's always been free to play our Sweepstake Coins games!{" "}
                  <br />
                  We've given away over 60 million free Sweepstake Coins.
                  <br />
                  Coins without any purchases.
                </div>
                <br />
                <div className="button_section">
                  <Link className="common_button" to="#">
                    find out more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 for_position_banner">
              <img
                src={
                  require("../assets/images/banners/no-purchase-spin.png")
                    .default
                }
              />
            </div>
          </div>
        </div>
      </section>

      <section className="color_banner blue_bg d-none">
        <div className="container">
          <div className="row flex-md-row flex-column-reverse">
            <div className="col-md-6">
              <div className="banner_img_section">
                <img
                  src={
                    require("../assets/images/backgrounds/bonus-right-side.png")
                      .default
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner_text_section">
                <div className="common_heading text_end">
                  CLAIM YOUR FREE
                  <br /> SWEEPSTAKE COINS
                </div>
                <div className="common_title on_banner text_end">
                  Mail a request, win a competition or receive a free bonus when
                  you purchase Fun Play Coins.
                </div>
                <div className="button_section text_end">
                  <Link className="common_button" to="#">
                    find out more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services service-two">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="services_headline">
                Exciting casino-style games
              </div>
              <div className="services_text">
                We provide a selection of online casino-style games based on
                Roulette, Blackjack, Video Poker, Table Games and Big Jackpot
                slots.
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_headline">No Downloads Required</div>
              <div className="services_text">
                Get straight to action through our easy to play web browser
                games for your PC, Mac or mobile device.
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_headline">
                Exclusive First Time Offer
              </div>
              <div className="services_text">
                Try before you commit by taking advantage of our exclusive first
                time purchase offer.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
