import React, { useState, useEffect } from "react";
import api from "../services/api";
import { Link } from "react-router-dom";
const ImageGalary = (props) => {
  const [gameListsData, setGameListsData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const getGames = async () => {
    if (page == 1) {
      setGameListsData([]);
    }
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: ["popular"],
      specific_games: props.specificGames,
    });

    if (response.status === 200 && response.data) {
      // alert(gameListsData.length)
      const tempGameList = gameListsData;
      var mergedGameList = tempGameList.concat(
        response.data.games.popular.gamelist
      );
      setGameListsData(mergedGameList);
    }
  };

  useEffect(async () => {
    getGames();
  }, []);

  return (
    <>
      <div class="containery">
        <div id="carousel">
          {gameListsData.length > 0 &&
            gameListsData.map((game, index) => (
              <figure key={index}>
                <Link to={"/play/" + game.slug + "/real"}>
                  <img src={game.game_snapshot_url} />
                </Link>
              </figure>
            ))}
        </div>
      </div>
    </>
  );
};

export default ImageGalary;
