import React, { useState, useEffect } from "react";

const AccountActivityDataView = (props) => {
  const [results, setResults] = useState({});
  useEffect(() => {
    setResults(props.dataSet);
  }, [props]);

  return (
    <div className="transactions">
      <div className="transaction_row header_row bet_history">
        <div className="single_columns heading">Action</div>
        <div className="single_columns heading">Browser</div>
        <div className="single_columns heading">Date</div>
        <div className="single_columns heading">Device</div>
        <div className="single_columns heading">IP</div>
      </div>

      {results.length > 0 &&
        results.map((item, index) => (
          <div key={index} className="transaction_row bet_history">
            <div className="single_columns">{item.account_action}</div>
            <div className="single_columns">{item.browser}</div>
            <div className="single_columns">{item.date_created}</div>
            <div className="single_columns">{item.device}</div>
            <div className="single_columns">{item.ip_address}</div>

          </div>
        ))}
    </div>
  );
};

export default AccountActivityDataView;
