import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "services/auth";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { HeaderSection } from "../components/Navbar/NavbarElement";
import moment from "services/moment";
import { __ } from "services/Translator";
import DOMPurify from "dompurify";
import HeaderWalletBalance from "../pages/players/parts/HeaderWalletBalance";
import BuyGoldCoins from "./BuyGoldCoins";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useSelector, useDispatch } from "react-redux";
import { setDefaultCurrency } from "features/balance/userBalance";

const Header = (props) => {
  // const menuOpen = props.menuOpen;
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [timeZone, setTimeZone] = useState("America/New_York");

  const userBalance = useSelector((state) => state.userBalance);

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  // expand header before login
  const [redeemTooltipOpen, setRedeemTooltipOpen] = useState(false);
  const handleRedeemTooltipOpen = () => {
    setRedeemTooltipOpen(true);
  };
  const handleRedeemTooltipClose = () => {
    setRedeemTooltipOpen(false);
  };

  // expand header before login
  const [expandHeader, setExpandHeader] = useState(false);
  const toggleExpandHeader = () => {
    if (expandHeader === false) {
      setExpandHeader(true);
    } else {
      setExpandHeader(false);
    }
  };

  // sidebar
  const [showSidebar, setShowSidebar] = useState(false);
  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  // currency setting

  const toggleCurrency = async (ev) => {
    var selectedBalance;
    if (userBalance.selectedBalance === "SC") {
      selectedBalance = "GC";
    } else if (userBalance.selectedBalance === "GC") {
      selectedBalance = "SC";
    }

    dispatch(setDefaultCurrency(selectedBalance)); //for immediate action
    var response = await api.post("/sweepstake-switch-balance", {
      selectedBalance: selectedBalance,
    });

    if (response.status === 200) {
      dispatch(setDefaultCurrency(selectedBalance)); //Sometimes get users balance call fetches the old value and sets back default currency temporarily, this'll negate the effect
    } else {
      Swal.fire({
        title: "warning!",
        text: response.data.message,
        icon: "warning",
      });
    }
  };

  // notification
  const [notifications, setNotificactions] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const toggleNotification = () => {
    if (notificationModal === false) {
      setNotificationModal(true);
      getNotifications();
    } else {
      setNotificationModal(false);
    }
  };

  const getNotifications = async () => {
    var response = await api.post("/player/messages-and-notifications", {
      page: 1,
    });
    if (response.status === 200) {
      console.log(response.data.data);
      setNotificactions(response.data.data);
    }
  };

  // clock

  const [currentTime, setCurrentTime] = useState();
  const liveTime = () => {
    setCurrentTime(Date());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      liveTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    // ls.get("language");
    // ls.set("timeZone", "America/New_York");
    var getTime = ls.get("timeZone");
    if (getTime) {
      setTimeZone(getTime);
    }
  }, []);

  //open support sidebar
  const [showSupportSidebar, setShowSupportSidebar] = useState(false);
  useEffect(() => {
    if (showSupportSidebar === true) {
      props.showSupportSidebar(showSupportSidebar);
    }
  }, [showSupportSidebar]);

  //open Buy Modal
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showDeposiModal, setShowDeposiModal] = useState(false);
  const [buyGoldPlanData, setBuyGoldPlanData] = useState({
    planId: "",
    planPrice: "",
    GC: 0,
    SC: 0,
    openWallet: false,
  });
  const callDepositModal = (receiveVal) => {
    console.log("receiveVal", receiveVal);
    setBuyGoldPlanData({
      ...buyGoldPlanData,
      planId: receiveVal.planId,
      planPrice: receiveVal.planPrice,
      openWallet: receiveVal.openWallet,
      GC: receiveVal.GC,
      SC: receiveVal.SC,
    });
  };

  useEffect(async () => {
    if (props.isAuthenticated) {
      var verified = await isFullVerified();
      setVerified(verified);
    }
  }, [props.isAuthenticated]);

  const [verified, setVerified] = useState(false);

  const isFullVerified = async () => {
    // var response = await api.get("/id-uploads");
    // if (
    //   response.data.poif &&
    //   response.data.poib &&
    //   response.data.poif.approved == "approved" &&
    //   response.data.poib.approved == "approved" &&
    //   response.data.poa &&
    //   response.data.poa.approved == "approved" &&
    //   response.data.sof &&
    //   response.data.sof.approved == "approved"
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }

    var response = await api.get("/plaid/id-verification/status");
    return response.status === 200 && response.data.status && response.data.status === "success" ? true : false;
  };

  const redeemSweepCoin = async () => {
    var verified = await isFullVerified();
    if (verified) {
      history.push("/redeem");
    } else {
      history.push("/redeem-rules");
    }
    setShowSidebar(false);
  };

  const makeSweepStakeTooltip = () => {
    var tooltip = (
      <>
        {__(
          "Sweepstake Coins can be used to play sweepstake games for a chance to win more Sweepstake Coins which are redeemable for real prizes. 1 Sweepstake Coin can be redeemed for $1 USD (subject to minimum balance)."
        )}
        <br />
        <br />

        <div>
          {__("Sweepstake Coin Balance")}: SC {userBalance.cash_balance}
        </div>
        <div>
          {__("Available to Redeem")}: SC {userBalance.redeemableAmount ? userBalance.redeemableAmount.toFixed(2) : ""}
        </div>
        <div>
          {__("Unplayed Balance")}: SC{" "}
          {userBalance.redeemableAmount ? (userBalance.cash_balance - userBalance.redeemableAmount).toFixed(2) : ""}
        </div>
      </>
    );
    return tooltip;
  };

  return (
    <>
      <BuyGoldCoins callDepositModal={callDepositModal} showBuyModal={showBuyModal} {...props} />

      <HeaderSection scrollNav={scrollNav}>
        <section className={expandHeader ? "header expand_mobile" : "header"}>
          {/* <div className="clock">{moment(currentTime).format("LTS")}</div> */}
          <div className="clock">
            <div class="dropdown">
              <button
                class="dropdown-toggle dropdownBtn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {moment.tz(timeZone).format("LTS")}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  onClick={() => {
                    setTimeZone("America/New_York");
                    ls.set("timeZone", "America/New_York");
                  }}
                  class="dropdown-item"
                >
                  NY - {moment.tz("America/New_York").format("LTS")}
                </a>
                <a
                  onClick={() => {
                    setTimeZone("America/Los_Angeles");
                    ls.set("timeZone", "America/Los_Angeles");
                  }}
                  class="dropdown-item"
                >
                  LA - {moment.tz("America/Los_Angeles").format("LTS")}
                </a>
                {/* <a
                  onClick={() => {
                    setTimeZone("America/Los_Angeles");
                    ls.set("timeZone", "America/Los_Angeles");
                  }}
                  class="dropdown-item"
                >
                  CT - {moment.tz("America/Connecticut").format("LTS")}
                </a> */}
              </div>
            </div>
          </div>
          <div className="container for_notification_relative">
            <div className="row">
              <div
                className={
                  isAuthenticated
                    ? "d-none col-sm-3 d-sm-block d-md-block d-lg-block col-md-3 col-lg-3"
                    : "d-block d-sm-block d-md-block col-7 col-sm-3 col-md-3 col-lg-3"
                }
              >
                <div className="logo">
                  {!isAuthenticated && (
                    <div className="d-inline d-sm-none d-md-none d-lg-none d-xl-none">
                      <Link onClick={toggleExpandHeader} to="#">
                        {expandHeader && <i className="fas fa-times"></i>}
                        {!expandHeader && <i className="fas fa-bars"></i>}
                      </Link>
                    </div>
                  )}

                  <Link to="/">
                    <img src={require("../assets/images/icons/wild-world-casino.svg").default} />
                  </Link>
                </div>
              </div>
              <div
                className={
                  isAuthenticated
                    ? "col-12 col-sm-9 col-md-9 col-lg-9 align-self-center"
                    : "col-5 col-sm-9 col-md-9 col-lg-9 align-self-center"
                }
              >
                {isAuthenticated && (
                  <>
                    <div className="currency_section">
                      <div className="currency-bar hud__primary-currency-bar currency-bar--goldBar">
                        <div className="currency_section">
                          <div class="base-button btn-link side-bar__item side-bar__switch-currency-item sidebar_currency_btn">
                            <label onChange={toggleCurrency} class="currency-toggle side-bar__currency-toggle toggle ">
                              {userBalance.selectedBalance == "SC" && <input type="checkbox" defaultChecked={true} />}
                              {userBalance.selectedBalance == "GC" && <input type="checkbox" defaultChecked={false} />}

                              <span class="switch">
                                <span class="switch__slider"></span>
                                <span class="switch__fixed-icon--left"></span>
                                <span class="switch__fixed-icon--right"></span>
                              </span>
                            </label>
                          </div>
                          <span className="counter_balance">
                            <span>
                              {userBalance.selectedBalance === "SC"
                                ? userBalance.cash_balance + " SC"
                                : userBalance.bonus_balance + " GC"}
                            </span>
                          </span>
                        </div>
                      </div>

                      <HeaderWalletBalance buyGoldPlanData={buyGoldPlanData} {...props} />

                      <div className="header_button_group">
                        <li className="d-block">
                          <Link
                            onClick={() => {
                              setShowBuyModal(true);
                              setTimeout(function () {
                                setShowBuyModal(false);
                              }, 500);
                            }}
                            className="buy_btn"
                            to="#"
                          >
                            Buy
                          </Link>
                        </li>
                        <li>
                          <Link onClick={toggleNotification} className="notification_btn notify-bell" to="#">
                            <img src={require("../assets/images/icons/notification.svg").default} />
                            {!!props.notificationCount && (
                              <span className="notifications_count">{props.notificationCount}</span>
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link onClick={openSidebar} className="notification_btn bars" to="#">
                            <i className="fas fa-bars"></i>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </>
                )}
                {!isAuthenticated && (
                  <>
                    <div className="menus">
                      <ul>
                        <li className="d-none d-sm-block d-md-block">
                          <Link to="/home">Games</Link>
                        </li>
                        <li className="d-none d-sm-block d-md-block">
                          <Link to="/about-us">About</Link>
                        </li>
                        <li className="d-none d-sm-block d-md-block">
                          <Link
                            onClick={() => {
                              setShowSupportSidebar(true);
                              setTimeout(function () {
                                setShowSupportSidebar(false);
                              }, 500);
                            }}
                          >
                            Contact
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() => {
                              setExpandHeader(false);
                            }}
                            // onClick={() => {
                            //   setIsloggedIn(true);
                            // }}
                            // className="active"
                            to="/login"
                          >
                            Login
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* if expand */}
            {expandHeader && (
              <div className="row">
                <div className="menus">
                  <ul className="center_align">
                    <li>
                      <Link
                        onClick={() => {
                          setExpandHeader(false);
                        }}
                        to="/home"
                      >
                        Games
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setExpandHeader(false);
                        }}
                        to="/about-us"
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSupportSidebar(true);
                          setExpandHeader(false);
                          setTimeout(function () {
                            setShowSupportSidebar(false);
                          }, 500);
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {notificationModal && (
              <>
                <div className="invisible_overlay transparent" onClick={toggleNotification}></div>
                <div class="notification-hud">
                  <h5>{__("Notifications")}</h5>
                  <div class="notification-hud__scrollable">
                    <ul class="list-unstyled">
                      {notifications
                        .filter((item, index) => index <= 1)
                        .map((item, index) => (
                          <li>
                            <div class="notification-hud__element actioned">
                              <div class="notification-hud__element-inner">
                                <div class="notification-hud__element-icon">
                                  <img src={require("../assets/images/icons/notification-icon.svg").default} />
                                </div>
                                <div class="notification-hud__element-copy">
                                  {item.type === "message" ? (
                                    <>
                                      <h6>{item.data.message_subject}</h6>
                                      <p
                                        class="message-body"
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(item.data.message_body.substr(0, 150)),
                                        }}
                                      ></p>
                                    </>
                                  ) : (
                                    <>
                                      <p
                                        class="message-body"
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(item.data.notification_str.substr(0, 150)),
                                        }}
                                      ></p>
                                    </>
                                  )}
                                  <p class="timestamp">{moment(item.created_at).fromNow()}</p>
                                </div>
                                <div class="notification-hud__element-dot-container"></div>
                              </div>
                            </div>
                          </li>
                        ))}
                      <li>
                        <Link class="read_all_notifications" to="/messages">
                          {__("Read all")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
          {showSidebar && (
            <div
              onClick={() => {
                setShowSidebar(false);
              }}
              className="invisible_overlay"
            ></div>
          )}

          <div className="custom_sidebar">
            <div id="mySidenav" className={"sidenav " + (showSidebar ? "open" : "")}>
              <div className="sidebar_logo">
                <Link
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                  to="/"
                >
                  <img width={100} src={require("../assets/images/icons/wild-world-casino.svg").default} />
                </Link>
              </div>
              <Link
                // to="javascript:void(0)"
                className="closebtn"
                onClick={closeSidebar}
              >
                &times;
              </Link>

              <div className="logged_menu">
                <ul className="in_sidebar">
                  <li className="no_hover">
                    <div className="currency_section">
                      <div class="base-button btn-link side-bar__item side-bar__switch-currency-item sidebar_currency_btn">
                        <label onChange={toggleCurrency} class="currency-toggle side-bar__currency-toggle toggle ">
                          {userBalance.selectedBalance == "SC" && <input type="checkbox" defaultChecked={true} />}
                          {userBalance.selectedBalance == "GC" && <input type="checkbox" defaultChecked={false} />}

                          <span class="switch">
                            <span class="switch__slider"></span>
                            <span class="switch__fixed-icon--left"></span>
                            <span class="switch__fixed-icon--right"></span>
                          </span>
                        </label>
                        <span className="currency_name">
                          {userBalance.selectedBalance === "GC" && "Gold Coin"}
                          {userBalance.selectedBalance === "SC" && "Sweepstake Coin"}
                        </span>
                      </div>
                    </div>
                  </li>

                  <li className="sidebar_balance no_hover">
                    <img className="icon" src={require("../assets/images/icons/sweep-coin.svg").default} />
                    <span> {userBalance.cash_balance}</span>
                    <ClickAwayListener onClickAway={handleRedeemTooltipClose}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleRedeemTooltipClose}
                          open={redeemTooltipOpen}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          enterTouchDelay={1}
                          title={makeSweepStakeTooltip()}
                          arrow
                        >
                          <span
                            onClick={handleRedeemTooltipOpen}
                            className="far fa-info-circle"
                            aria-hidden="true"
                          ></span>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </li>

                  <li className="sidebar_balance no_hover">
                    <img className="icon" src={require("../assets/images/icons/fun-coin.svg").default} />
                    <span> {userBalance.bonus_balance}</span>
                  </li>

                  <li className="button_group_sidebar">
                    <Link
                      onClick={() => {
                        setShowBuyModal(true);
                        setTimeout(function () {
                          setShowBuyModal(false);
                        }, 500);
                      }}
                      className="buy_btn"
                      to="#"
                    >
                      buy
                    </Link>
                    <Link
                      onClick={() => {
                        // setShowSidebar(false);
                        redeemSweepCoin();
                      }}
                      className="buy_btn no_bg"
                      to="#"
                    >
                      REDEEM
                      {!verified && (
                        <img
                          src={require("../assets/images/icons/red-lock.svg").default}
                          id="status-icon__unverified"
                        />
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      to="/home"
                    >
                      <svg viewBox="0 0 40 40" fill="none">
                        <path
                          className="path"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20.6076 0.264512C20.2001 -0.0895026 19.6088 -0.0880112 19.203 0.268056L0.391591 16.7724C-0.0720982 17.1792 -0.132656 17.9021 0.256331 18.387C0.645317 18.872 1.33655 18.9353 1.80024 18.5285L5.58906 15.2044V36.7908C5.58906 38.5632 6.96287 40 8.65755 40H31.3424C33.0371 40 34.4109 38.5632 34.4109 36.7908V15.2368L38.2038 18.532C38.6694 18.9365 39.3603 18.8697 39.7471 18.3828C40.1338 17.8959 40.0699 17.1733 39.6043 16.7688L20.6076 0.264512ZM32.2192 13.3326L19.9108 2.63913L7.78084 13.2814V36.7908C7.78084 37.2972 8.17335 37.7077 8.65755 37.7077H13.6986V22.5107C13.6986 21.8778 14.1893 21.3646 14.7945 21.3646H25.1246C25.7298 21.3646 26.2204 21.8778 26.2204 22.5107V37.7077H31.3424C31.8266 37.7077 32.2192 37.2972 32.2192 36.7908V13.3326ZM24.0287 23.6569V37.7077H15.8904V23.6569H24.0287Z"
                          fill="#F5F5F5"
                        ></path>
                      </svg>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      to="/games/slots"
                    >
                      <svg viewBox="0 0 40 40" fill="none">
                        <path
                          className="path"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.14607 0C1.40854 0 0 1.46193 0 3.26531V36.7347C0 38.5381 1.40854 40 3.14607 40H36.8539C38.5915 40 40 38.5381 40 36.7347V3.26531C40 1.46193 38.5915 0 36.8539 0H3.14607ZM2.24719 3.26531C2.24719 2.75006 2.64963 2.33236 3.14607 2.33236H36.8539C37.3504 2.33236 37.7528 2.75005 37.7528 3.26531V7.28863H2.24719V3.26531ZM26.9663 9.62099H37.7528V29.6793H26.9663V9.62099ZM25.2809 29.6793V9.62099H14.8315V29.6793H25.2809ZM13.1461 29.6793V9.62099H2.24719V29.6793H13.1461ZM2.24719 32.0117V36.7347C2.24719 37.2499 2.64963 37.6676 3.14607 37.6676H36.8539C37.3504 37.6676 37.7528 37.2499 37.7528 36.7347V32.0117H2.24719ZM9.21185 21.5067C8.97721 22.3031 8.8599 23.0253 8.8599 23.6735H6.0542C6.0542 23.0598 6.17813 22.3806 6.42598 21.636C6.67383 20.8913 6.98613 20.169 7.36286 19.4691C7.7396 18.7693 8.11964 18.1573 8.50299 17.6333H7.02579V18.5848H4.94382V15.6268H11.1699V17.1989C10.8064 17.7643 10.4478 18.4245 10.0942 19.1796C9.7406 19.9346 9.44648 20.7103 9.21185 21.5067ZM21.2112 21.5067C20.9765 22.3031 20.8592 23.0253 20.8592 23.6735H18.0535C18.0535 23.0598 18.1775 22.3806 18.4253 21.636C18.6732 20.8913 18.9855 20.169 19.3622 19.4691C19.7389 18.7693 20.119 18.1573 20.5023 17.6333H19.0251V18.5848H16.9431V15.6268H23.1692V17.1989C22.8057 17.7643 22.4471 18.4245 22.0935 19.1796C21.7399 19.9346 21.4458 20.7103 21.2112 21.5067ZM32.8586 23.6735C32.8586 23.0253 32.9759 22.3031 33.2105 21.5067C33.4451 20.7103 33.7393 19.9346 34.0929 19.1796C34.4465 18.4245 34.805 17.7643 35.1685 17.1989V15.6268H28.9425V18.5848H31.0244V17.6333H32.5016C32.1183 18.1573 31.7383 18.7693 31.3615 19.4691C30.9848 20.169 30.6725 20.8913 30.4246 21.636C30.1768 22.3806 30.0529 23.0598 30.0529 23.6735H32.8586Z"
                          fill="#F5F5F5"
                        ></path>
                      </svg>
                      Slots
                    </Link>
                  </li>
                </ul>
              </div>
              <hr></hr>
              <div className="custom_sidebar_body">
                <ul>
                  <li>
                    <button className="toggle-button toggle_button" id="side-bar__sound-toggle-btn">
                      Sound
                      <label className="sound-toggle toggle " for="sound-toggle">
                        <input type="checkbox" id="sound-toggle" checked="on" />
                        <span className="switch">
                          <span className="switch__slider"></span>
                          <span className="switch__fixed-icon--left"></span>
                          <span className="switch__fixed-icon--right"></span>
                        </span>
                      </label>
                      <b>ON</b>
                    </button>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link active"
                      to="/refer-and-earn"
                    >
                      {__("Refer and Earn")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link active"
                      to="/gameplay-history"
                    >
                      Gameplay History
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link active"
                      to="/history"
                    >
                      Purchase & Prize History
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link active"
                      to="/account-activity"
                    >
                      Account Activity
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link active"
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      to="/responsible-gaming-settings"
                    >
                      Responsible Gaming Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link active"
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      to="/account-verification"
                    >
                      Account Verification
                    </Link>
                  </li>
                  {props.isAuthenticated && (
                    <li>
                      <Link
                        className="link active"
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        to="/favorite-games"
                      >
                        My Favourite Games
                      </Link>
                    </li>
                  )}

                  <hr></hr>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link active"
                      to="/promotions"
                    >
                      Promotions
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link active"
                      to="/terms-and-conditions"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link"
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link"
                      to="/sweeps-rules"
                    >
                      Sweeps Rules
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSidebar(false);
                      }}
                      className="link"
                      to="/responsible-social-gameplay-policy"
                    >
                      Responsible Social Gameplay
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShowSupportSidebar(true);
                        setExpandHeader(false);
                        setTimeout(function () {
                          setShowSupportSidebar(false);
                        }, 500);
                      }}
                      className="link"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <hr></hr>
                  <li className="logout">
                    <Link onClick={logout} className="link" to="#">
                      <i className="fal fa-power-off menu_icon"></i> Log Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </HeaderSection>
    </>
  );
};

export default Header;
