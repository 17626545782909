import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import SearchBox from "elements/SearchBox";
import SortingBox from "elements/SortingBox";
import ProvidersFilter from "elements/ProvidersFilter";

const GameMenu = (props) => {
  // const menuOpen = props.menuOpen;
  const [categoryName, setCategoryName] = useState("Slots");
  const __ = (text) => text;

  return (
    <>
      <div className="main_game_menu_area">
        <ul className="gameMenu">
          <li>
            <Link
              className={" " + (categoryName == "Slots" ? "active" : "")}
              onClick={() => {
                props.setCategoryName("Slots");
              }}
            >
              {__("SLOTS")}
            </Link>
          </li>
          {/* <li>
            <Link
              className={" " + (categoryName == "Jackpots" ? "active" : "")}
              onClick={() => {
                props.setCategoryName("Jackpots");
              }}
            >
              {__("JACKPOTS")}
            </Link>
          </li> */}
          <li>
            <Link
              className={" " + (categoryName == "Table Games" ? "active" : "")}
              onClick={() => {
                props.setCategoryName("Table Games");
              }}
            >
              {__("TABLE GAMES")}
            </Link>
          </li>
          <li>
            <Link
              className={" " + (categoryName == "All Games" ? "active" : "")}
              onClick={() => {
                props.setCategoryName("All Games");
              }}
            >
              {__("ALL GAMES")}
            </Link>
          </li>
          <li>
            <Link
              className={" " + (categoryName == "New Games" ? "active" : "")}
              onClick={() => {
                props.setCategoryName("New Games");
              }}
            >
              {__("New Games")}
            </Link>
          </li>
          <li>
            <ProvidersFilter {...props} />
          </li>
          <li>
            <SearchBox {...props} />
          </li>
          {/* <li>
            <SortingBox {...props} />
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default GameMenu;
