import styled from "styled-components";

export const HeaderSection = styled.header`
 background: ${({scrollNav}) => (scrollNav ? '#000' : '#000')};
position: fixed;
top: 0;
width: 100%;
left: 0;
z-index: 999;
box-shadow:${({scrollNav}) => (scrollNav ? '0px 2px 2rem rgba(0, 0, 0, 0.2)' : 'none')};
backdrop-filter: blur(8px);
border-bottom:${({scrollNav}) => (scrollNav ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
transition: height 2s !important;
align-items: center;
padding: 15px 0;
`;