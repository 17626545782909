import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTo() {
  const routePath = useLocation();
  const onTop = () => {
    // window.scrollTo(0, 0);
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
}
