import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "../../services/api";
import TransactionMobileDataView from "./parts/TransactionMobileDataView";
import TransactionDataView from "./parts/TransactionDataView";
import { isMobile } from "../../services/isMobile";
import Pagination from "../../elements/Pagination";

export default function BetHistory(props) {
  const __ = (text) => text;
  const defaultForm = {
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };
  const [page, setPage] = useState(1);

  const [transactionType, setTransactionType] = useState("Deposits");
  const getResults = async () => {
    var response = await api.post("/history/transactions", {
      page: page,
      historyFor: transactionType,
    });

    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    getResults();
  }, [transactionType, page]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  return (
    <>
      <div className="profile">
        <div className="container">
          <div className="row">
            <div className={isMobileDevice ? "col-md-12" : "col-md-12"}>
              <div className="profile_right_content">
                <div className="profile_headline">
                  Purchase & Prize History
                  <span className="toggle_content">
                    <div class="transaction_mode">
                      <Link
                        to="#"
                        onClick={() => setTransactionType("Deposits")}
                        className={
                          "mode_btn " +
                          (transactionType == "Deposits" ? "active" : "")
                        }
                      >
                        Purchase
                      </Link>
                      <Link
                        to="#"
                        onClick={() => setTransactionType("Withdrawals")}
                        className={
                          "mode_btn " +
                          (transactionType == "Withdrawals" ? "active" : "")
                        }
                      >
                        Prize History
                      </Link>
                    </div>
                  </span>
                </div>
                <br />
                <div className="clearfix"></div>

                {!isMobileDevice && (
                  <TransactionDataView
                    dataSet={results}
                    transactionType={transactionType}
                  />
                )}

                {isMobileDevice && (
                  <TransactionMobileDataView
                    dataSet={results}
                    transactionType={transactionType}
                  />
                )}

                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
