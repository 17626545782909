import React from "react";

function ScrollDown(props) {
  return (
    <div className="scrolldown">
      <i class="fal fa-chevron-double-down"></i>
      <i class="fal fa-chevron-down"></i>
    </div>
  );
}

export default ScrollDown;
