import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import api from "services/api";
import moment from "moment";
import Translator, { __ } from "services/Translator";
import { Modal, Button, Alert, Dropdown, Form, Overlay, Tooltip, Accordion } from "react-bootstrap";

export default function Affiliate(props) {
  const defaultForm = {
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };

  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const [profile, setProfile] = useState({});
  const getProfile = async () => {
    var response = await api.get("/profile");
    // console.log("ProfileResponse", response);
    if (response.status === 200) {
      setProfile(response.data);
    }
  };

  const getResults = async () => {
    props.updateSpinner(true);
    var response = await api.post("/player-affiliate/referred-players", form);
    props.updateSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const d = new Date();
  let year = d.getFullYear();

  const [copiedTooltipShow, setCopiedTooltipShow] = useState(false);
  const affiliateAddressRef = useRef(null);

  const copyAffiliateAddress = () => {
    var copyText = document.getElementById("ref_url");
    navigator.clipboard.writeText(copyText.innerHTML);
    setCopiedTooltipShow(true);
    const timer = setTimeout(() => {
      setCopiedTooltipShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <div className="profile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile_right_content">
                <div className="profile_headline">{__("Referred Players")}</div>
                {!!profile?.referalCode && (
                  <div style={{ color: "white" }}>
                    {__("Referral URL")}:{" "}
                    <span id="ref_url">
                      {"https://wildworldcasino.com" +
                        generatePath("/ref/:referralCode", { referralCode: profile.referalCode })}
                    </span>
                    <a
                      className="in_input_icon"
                      onClick={copyAffiliateAddress}
                      href="javascript:"
                      ref={affiliateAddressRef}
                    >
                      <img alt="" src={require("assets/images/icons/copy-icon.svg").default} />
                    </a>
                    <Overlay target={affiliateAddressRef.current} show={copiedTooltipShow} placement="top">
                      {(props) => (
                        <Tooltip id="overlay-example" className="tooltip" {...props}>
                          {__("Copied")}
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>
                )}
                <br />
                <div className="clearfix"></div>

                {results.length ? (
                  <>
                    <div className="transactions">
                      <div className="transaction_row header_row bet_history">
                        <div className="single_columns heading">
                          <i className="fas fa-list"></i>
                          {__("Referred Player")}
                        </div>
                        <div className="single_columns heading">{__("Registered On")}</div>
                      </div>

                      {results.map((item, index) => (
                        <div key={index} className="transaction_row bet_history">
                          <div className="single_columns">{item.referred_player_id}</div>
                          <div className="single_columns">{moment(item.updated_at).format("YYYY-MM-DD")}</div>
                        </div>
                      ))}

                      <div className="pagination">
                        <Pagination data={pagination} onPageChange={onPageChange} />
                      </div>
                    </div>
                  </>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
