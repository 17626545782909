import React from "react";

class SpinnerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: props.text ? props.text : null,
    };
  }

  render() {
    return (
      <div className="spinner_overlay">
        <img className="spinner" src={require("assets/images/icons/wild-world-casino.svg").default} />
        {!!this.state.text && (
          <>
            <div className="spinner_text">
              <div className="inner">{this.state.text}</div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SpinnerComponent;
