import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <>
      <section className="contact_page">
        <div className="blocks">
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">CASINO</div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">account</div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">
                    bonuses and promotions
                  </div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">payments</div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">verification</div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">prize redemption</div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">
                    games and technical issues
                  </div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="blocks-item ">
                <Link to="/contact-details" className="blocks-item-link">
                  <div className="blocks-item-title">responsile gameplay</div>
                  <div className="blocks-item-description"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        
      </section>
    </>
  );
}
