import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Button, Dropdown, Collapse } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import config from "configs/config.js";
// import { __ } from "services/Translator";

const FavoriteGame = (props) => {
  const __ = (text) => text;
  const [isFavorite, setIsFavorite] = useState(props.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", {
      gameId: props.gameid,
    });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };
  return (
    <>
      {props.isAuthenticated && (
        <div onClick={toggleFavoriteGame} className="favoriteGameArea">
          <i className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
        </div>
      )}
    </>
  );
};

export default FavoriteGame;
