import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import CategoryGames from "../elements/CategoryGames";
import api from "services/api";
import GameMenu from "elements/GameMenu";
import FavoriteGame from "elements/FavoriteGame";
export default function FavoriteGames(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.updateSpinner(true);
    var response = await api.post("/games/favorites", form);
    props.updateSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <section className="game_content_area container">
      <br />
      <br />
      <div className="common_headline">My Games</div>

      <div className="games_list">
        {results.length > 0 &&
          results.map((game, index) => (
            <React.Fragment key={index}>
              <div className="single_game">
                <div className="game_thumb">
                  <img src={game.game_snapshot_url} />
                  <div className="identyText-overview">
                    {/* <h6 className="game_name">{game.game_name}</h6> */}

                    <div class="game_action">
                      <Link
                        class="game_play_icon"
                        to={"/play/" + game.slug + "/real"}
                      >
                        <i class="fas fa-play"></i>
                      </Link>
                    </div>
                    <h3 class="game_provider_name">{game?.provider?.name}</h3>
                    {/* <Link
                          className=" common_button btnType-three"
                          to={"/play/" + game.slug + "/real"}
                        >
                          <span>Play now</span>
                        </Link> */}

                    {/* <Link className="common_button btnType-three" to="#">
                          <span>Play now</span>
                        </Link> */}
                  </div>
                  <FavoriteGame
                    gameid={game.id}
                    is_favorite_game={game.is_favorite_game}
                    {...props}
                  />
                </div>
                <h4 className="game_title">{game.game_name}</h4>
                {/* <div className="mask_layer"></div> */}

                {/* <div className="identyText-overview">
                      <p>{game.game_name}</p>
                    </div> */}
              </div>
            </React.Fragment>
          ))}
      </div>

      <Link
        onClick={() => onPageChange(form.page + 1)}
        className="loadMoreBtn d-none"
      >
        Load More
      </Link>
    </section>
  );
}
