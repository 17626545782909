import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import moment from "services/moment";
import "react-datepicker/dist/react-datepicker.css";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";

import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";
import { isMobile } from "services/isMobile";
import Swal from "sweetalert2";
import { __ } from "services/Translator";

import { Accordion, Button, Modal } from "react-bootstrap";

export default function Verify(props) {
  // const __ = (text) => text;

  const { updateUserObj } = React.useContext(AppContext);

  const [plaidVerified, setPlaidVerified] = useState(false);
  const [level1Verification, setLevel1Verification] = useState(false);

  const getPlaidVerified = async () => {
    var response = await api.get("/plaid/id-verification/status");
    if (response.status === 200) {
      setPlaidVerified(response.data.status);
    }
  };

  const startPlaidVerification = async () => {
    // if (!level1Verification) {
    //   Swal.fire({
    //     className: "custom_swal",
    //     title: __("Error"),
    //     text: __("Please complete level 1 verification first."),
    //     icon: "error",
    //   });
    //   return;
    // }
    var response = await api.post("/plaid/id-verification/create", {});
    if (response.status === 200) {
      console.log(response.data);
      // alert(response.data.verification.shareable_url);
      window.open(response.data.verification.shareable_url, "_self");
    }
  };

  useEffect(async () => {
    getProfile();
    getPlaidVerified();
  }, []);

  //Level 1 functionality START'S here
  const [basicProfileErrors, setBasicProfileErrors] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    address: "",
    dateOfBirth: "",
    city: "",
    zip: "",
    occupation: "",
  });
  const [basicProfileData, setBasicProfileData] = useState({
    firstName: "",
    lastName: "",
    bday: 1,
    bmonth: 1,
    byear: 2003,
    playerCountry: "",
    address: "",
    city: "",
    zip: "",
    occupation: "",
  });

  const updateLevelOneInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    if (field === "playerCountry" && ["US", "CA", "AU", "JP"].indexOf(value) !== -1) {
      getAllStates(value);
    }
    setBasicProfileData({ ...basicProfileData, [field]: value });
  };

  // const [mainProfileData, setMainProfileData] = useState();
  const [mainProfileData, setMainProfileData] = useState({
    firstName: "",
    lastName: "",
    bday: 1,
    bmonth: 1,
    byear: 2003,
    playerCountry: "",
    address: "",
    city: "",
    zip: "",
    occupation: "",
  });

  const getProfile = async () => {
    var response = await api.get("/profile");
    if (response.status === 200 && response.data) {
      if (["US", "CA", "AU", "JP"].indexOf(response.data.playerCountry) !== -1) {
        getAllStates(response.data.playerCountry);
      }
      setBasicProfileData(response.data);
      setMainProfileData(response.data);
      if (
        response.data.firstName &&
        response.data.lastName &&
        response.data.bday &&
        response.data.bmonth &&
        response.data.byear &&
        response.data.playerCountry &&
        response.data.address &&
        response.data.city &&
        response.data.zip &&
        (["US", "CA", "AU", "JP"].indexOf(response.data.playerCountry) === -1 || response.data.state)
      ) {
        setLevel1Verification(true);
      } else {
        setLevel1Verification(false);
      }
    }
  };

  const saveBasicProfileData = async (ev) => {
    setBasicProfileErrors({
      firstName: "",
      lastName: "",
      nickName: "",
      address: "",
      dateOfBirth: "",
      city: "",
      zip: "",
      state: "",
      occupation: "",
    });
    var response = await api.post("/profile/save-basic", basicProfileData);
    // console.log(response);
    if (response.status == 200) {
      Swal.fire({
        className: "custom_swal",
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
    } else {
      if (response.data.errors) {
        setBasicProfileErrors(response.data.errors);
      } else {
        Swal.fire({
          className: "custom_swal",
          title: "Error",
          text: response.data.message,
          icon: "error",
        });
      }
    }
  };

  const [allCountries, setAllCountries] = useState([]);
  const getAllCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setAllCountries(response.data);
    }
  };

  const [allStates, setAllStates] = useState([]);
  const getAllStates = async (countryCode) => {
    var response = await api.get("/states/" + countryCode);
    if (response.status === 200 && response.data) {
      setAllStates(response.data);
    }
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
    getAllCountries();
  }, []);

  return (
    <>
      <div className="container">
        <div className="col-md-6">
          <br />
          <div className="common_headline">Account Verification</div>
          <Accordion className="chimp-accordions verify_area access_control_area" alwaysOpen>
            <Accordion.Item className="chimp-accordion" eventKey="0">
              <Accordion.Header>
                {__("Level 1")} &nbsp;{" "}
                {level1Verification ? (
                  <i class="fas fa-check"></i>
                ) : (
                  <i class={"fas fa-exclamation " + (level1Verification ? "submitted" : "")}></i>
                )}
              </Accordion.Header>
              <Accordion.Body className="myaccount_input_area">
                <div className="user_blocks">
                  <div className="single_block twoItems">
                    <div className="item">
                      <div className="input_label">
                        {__("First Name")}
                        <span className="requried_icon"> *</span>
                      </div>

                      <input
                        type="text"
                        name="firstName"
                        placeholder={__("First Name")}
                        disabled={mainProfileData.firstName ? true : false}
                        value={basicProfileData.firstName}
                        onChange={updateLevelOneInput}
                        className={"form-control input_box " + (basicProfileErrors.firstName ? "error" : "")}
                      />
                      <div class="errorMsg">{basicProfileErrors.firstName}</div>
                    </div>
                    <div className="item">
                      <div className="input_label">
                        {__("Last Name")}
                        <span className="requried_icon"> *</span>
                      </div>

                      <input
                        type="text"
                        name="lastName"
                        disabled={mainProfileData.lastName ? true : false}
                        value={basicProfileData.lastName}
                        onChange={updateLevelOneInput}
                        placeholder={__("Last Name")}
                        className={"form-control input_box " + (basicProfileErrors.lastName ? "error" : "")}
                      />
                      <div class="errorMsg">{basicProfileErrors.lastName}</div>
                    </div>
                  </div>

                  <div className="single_block threeItems">
                    <div className="item">
                      <div className="input_label">
                        {__("Date of Birth")}
                        <span className="requried_icon"> *</span>
                      </div>

                      <input
                        autocomplete="off"
                        className="form-control input_box"
                        type="number"
                        name="bday"
                        disabled={mainProfileData.bday ? true : false}
                        value={basicProfileData.bday}
                        onChange={updateLevelOneInput}
                        step="1"
                        min="1"
                        placeholder={__("Day")}
                        max="31"
                      />
                    </div>
                    <div className="item">
                      <div className="input_label">&nbsp;</div>
                      <input
                        autocomplete="off"
                        className="form-control input_box"
                        type="number"
                        name="bmonth"
                        disabled={mainProfileData.bmonth ? true : false}
                        value={basicProfileData.bmonth}
                        onChange={updateLevelOneInput}
                        step="1"
                        min="1"
                        max="12"
                        placeholder={__("Month")}
                      />
                    </div>
                    <div className="item">
                      <div className="input_label">&nbsp;</div>
                      <input
                        autocomplete="off"
                        className="form-control input_box"
                        type="number"
                        name="byear"
                        disabled={mainProfileData.byear ? true : false}
                        value={basicProfileData.byear}
                        onChange={updateLevelOneInput}
                        step="1"
                        min="1900"
                        placeholder={__("Year")}
                      />
                    </div>
                    <div class="errorMsg">{basicProfileErrors.dateOfBirth}</div>
                  </div>
                  <div
                    className={
                      "single_block " +
                      (["US", "CA", "AU", "JP"].indexOf(basicProfileData.playerCountry) !== -1
                        ? "twoItems"
                        : "singleItem")
                    }
                  >
                    <div className="item">
                      <div className="input_label">
                        {__("Country")}
                        <span className="requried_icon"> *</span>
                      </div>
                      <select
                        name="playerCountry"
                        value={basicProfileData.playerCountry}
                        onChange={updateLevelOneInput}
                        className="form-control input_box"
                        disabled={mainProfileData.playerCountry ? true : false}
                      >
                        <option>{__("Select Country")}</option>
                        {allCountries.map((item, index) => (
                          <option value={item.code}>{item.nicename}</option>
                        ))}
                      </select>
                    </div>
                    {["US", "CA", "AU", "JP"].indexOf(basicProfileData.playerCountry) !== -1 && (
                      <div className="item">
                        <div className="input_label">
                          {__("State")}
                          <span className="requried_icon"> *</span>
                        </div>
                        <select
                          name="state"
                          value={basicProfileData.state}
                          onChange={updateLevelOneInput}
                          className="form-control input_box"
                          disabled={mainProfileData.state ? true : false}
                        >
                          <option>{__("Select State")}</option>
                          {allStates.map((item, index) => (
                            <option value={item.iso2}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="single_block singleItem">
                    <div className="item">
                      <div className="input_label">
                        {__("Residential Address")} <span className="requried_icon"> *</span>
                      </div>
                      <input
                        type="text"
                        name="address"
                        value={basicProfileData.address}
                        disabled={mainProfileData.address ? true : false}
                        placeholder={__("Residential Address")}
                        onChange={updateLevelOneInput}
                        className={"form-control input_box " + (basicProfileErrors.address ? "error" : "")}
                      />
                      <div class="errorMsg">{basicProfileErrors.address}</div>
                    </div>
                  </div>

                  <div className="single_block twoItems">
                    <div className="item">
                      <div className="input_label">
                        {__("City")} <span className="requried_icon"> *</span>
                      </div>
                      <input
                        type="text"
                        name="city"
                        value={basicProfileData.city}
                        disabled={mainProfileData.city ? true : false}
                        onChange={updateLevelOneInput}
                        placeholder={__("City")}
                        className={"form-control input_box " + (basicProfileErrors.city ? "error" : "")}
                      />
                      <div class="errorMsg">{basicProfileErrors.city}</div>
                    </div>
                    <div className="item">
                      <div className="input_label">
                        {__("Postal Code")} <span className="requried_icon"> *</span>
                      </div>

                      <input
                        type="text"
                        name="zip"
                        value={basicProfileData.zip}
                        onChange={updateLevelOneInput}
                        disabled={mainProfileData.zip ? true : false}
                        className={"form-control input_box " + (basicProfileErrors.zip ? "error" : "")}
                        placeholder={__("Postal Code")}
                      />
                      <div class="errorMsg">{basicProfileErrors.zip}</div>
                    </div>
                  </div>

                  <div className="single_block singleItem">
                    <div className="item">
                      <div className="input_label">
                        {__("Occupation")} ({__("Your job/work")})<span className="requried_icon"> *</span>
                      </div>
                      <input
                        className="form-control input_box"
                        type="text"
                        name="occupation"
                        disabled={mainProfileData.occupation ? true : false}
                        value={basicProfileData.occupation}
                        onChange={updateLevelOneInput}
                        placeholder={__("Occupation")}
                      />
                    </div>
                  </div>

                  <div style={{ alignItems: "center" }} className="d-flex justify-content-center">
                    {__("All data is safely stored and encrypted.")}
                    <button onClick={saveBasicProfileData} className="common_btn gray">
                      {__("Submit")}
                    </button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="chimp-accordion" eventKey="1">
              <Accordion.Header>
                {__("Level 2")} &nbsp;{" "}
                {plaidVerified === "success" ? (
                  <i class="fas fa-check"></i>
                ) : (
                  <i
                    class={
                      "fas fa-exclamation " +
                      (["failed", "expired", "canceled"].indexOf(plaidVerified) !== -1 ? "submitted" : "")
                    }
                  ></i>
                )}
              </Accordion.Header>
              <Accordion.Body className="myaccount_input_area">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form_area">
                      <div className="input_item">
                        <div className="name">
                          Please upload your Proof of Identity. All documents must be lying on a flat surface with all 4
                          corners inside the frame, and all information should be clear and identifiable. While
                          photographs of ID cards and Passport are acceptable, scanned documents are not.
                        </div>
                      </div>

                      {(!plaidVerified || ["success"].indexOf(plaidVerified) === -1) && (
                        <div className="login_button">
                          <button onClick={startPlaidVerification} className="common_btn gray">
                            {__("Start Verification")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}
