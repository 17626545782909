import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import CategoryGames from "../elements/CategoryGames";
import api from "services/api";
import GameMenu from "elements/GameMenu";
export default function Games(props) {
  const params = useParams();
  const [title, setTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (params.gameType == "slots") {
      setCategoryName("Popular Slots");
      setTitle("Popular Slots");
    } else if (params.gameType == "jackpot") {
      setCategoryName("Popular Slots");
      setTitle("Jackpots");
    } else if (params.gameType == "table-games") {
      setCategoryName("Popular Slots");
      setTitle("Table Games");
    } else if (params.gameType == "search") {
      setCategoryName("Search");
      setTitle("Search");
    }

    // alert(params.gameType)
  }, [params]);

  return (
    <div className="home">
      <div className="home_banner slots_banner">
        <div className="hero_details">
          <div className="delay fadeInUp">
            <img
              className="hero__logo"
              alt="hero banner logo"
              src={
                require("../assets/images/banners/slots_banner_left.png")
                  .default
              }
            />

            <p className="hero_description">
              Find your fortune and win the Dragon's Gold
            </p>
          </div>
          <div className="button_section">
            <Link to="/home" className="common_button">
              PLAY NOW
            </Link>
          </div>
        </div>
      </div>

      <GameMenu {...props} />
      <div className="container">
        <div className="content-featuresBox">
          {/* <CategoryGames categoryName="Popular Slots" /> */}

          <CategoryGames
            categoryName={categoryName}
            perPage="60"
            title={title}
            {...props}
          />
        </div>
      </div>
    </div>
  );
}
