import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Button, Dropdown, Collapse } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import auth from "services/auth";
import config from "configs/config.js";
import Slider from "react-slick";
import FavoriteGame from "elements/FavoriteGame";
// import { __ } from "services/Translator";

const RecentlyPlayedGames = (props) => {
  const __ = (text) => text;
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.updateSpinner(true);
    var response = await api.post("/games/recently-played", {
      page: 1,
      type: "all",
      perPage: 6,
    });
    props.updateSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      getResults();
    }
  }, []);

  const hideNoImageFoundGames = () => {
    // setVisible(false);
  };

  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
    arrows: true,
    slidesToShow: window.outerWidth < 576 ? 2 : window.outerWidth < 768 ? 3 : window.outerWidth < 992 ? 4 : 5,
    slidesToScroll: 1,
  };

  return (
    <>
      {props.isAuthenticated && (
        <section className="game_content_area">
          <div className="common_headline">Recently Played Games</div>

          <div className="games_list slider">
            <Slider {...sliderSettings}>
              {results.length > 0 &&
                results.map((game, index) => (
                  <div className="single_game" key={index}>
                    <div className="game_thumb">
                      <img onError={hideNoImageFoundGames} src={game.game_snapshot_url} />
                      <div className="identyText-overview">
                        {/* <h6 className="game_name">{game.game_name}</h6> */}

                        <div class="game_action">
                          {!isAuthenticated && (
                            <Link class="game_play_icon" to={"/play/" + game.slug + "/demo"}>
                              <i class="fas fa-play"></i>
                            </Link>
                          )}
                          {isAuthenticated && (
                            <Link class="game_play_icon" to={"/play/" + game.slug + "/real"}>
                              <i class="fas fa-play"></i>
                            </Link>
                          )}
                        </div>
                        <h3 class="game_provider_name">{game?.provider?.name}</h3>
                      </div>
                      <FavoriteGame gameid={game.id} is_favorite_game={game.is_favorite_game} {...props} />
                    </div>
                    <h4 className="game_title">{game.game_name}</h4>
                  </div>
                ))}
            </Slider>
          </div>
        </section>
      )}
    </>
  );
};

export default RecentlyPlayedGames;
