import React from "react";

export default function CheckBox({ children, type, ...rest }) {
  const newType = type ? type : "checkbox";

  return (
    <div className="checking">
      <div className="checkbox_with_text" style={styles.checkboxWithText}>
        <div className="checkbox_container" style={styles.checkboxContainer}>
          <input type={newType} {...rest} />
        </div>
        <div>{children}</div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

const styles = {
  checkboxWithText: {
    display: "flex",
  },
  checkboxContainer: {
    marginTop: "-2px",
    marginRight: "10px",
  },
};
