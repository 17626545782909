import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";

export default function ReviewSection() {
  const history = useHistory();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <>
      <div className="login_page review_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="login_headline">
                EXCITING ONLINE <br /> CASINO-STYLE
                <br />
                <span className="golden">GAMES</span>
              </div>
              <br />
              <div className="paragraph white">
                With over 680 games to choose from, <br />
                there is always something new to play.
              </div>
              <br />
              <br />
              <br />
              <Link className="common_button uppercase" to="/register">
                Create Account
              </Link>
            </div>
            <div className="col-md-6">
              <div className="register no_padding">
                <Slider {...settings}>
                  <div className="form_area login_area">
                    <div className="quats">
                      <img
                        src={
                          require("../assets/images/icons/quats.png").default
                        }
                      />
                    </div>
                    <div className="paragraph white uppercase open_sans">
                      Unsolicited testimonial from Indiana USA
                    </div>

                    <div className="form_title small">Larry H.</div>
                    <br />
                    <div className="indicatior in_review"></div>
                    <br />
                    <div className="paragraph white">
                      Games are awesome, redemptions are the quickest I have
                      ever seen.
                    </div>
                    <br />

                    <div className="stars">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                  <div className="form_area login_area">
                    <div className="quats">
                      <img
                        src={
                          require("../assets/images/icons/quats.png").default
                        }
                      />
                    </div>
                    <div className="paragraph white uppercase open_sans">
                      Unsolicited testimonial from USA
                    </div>

                    <div className="form_title small">Angel Hall</div>
                    <br />
                    <div className="indicatior in_review"></div>
                    <br />
                    <div className="paragraph white">
                      I absolutely love Wild World Casino! I have won big and
                      small, never had issues with redeeming my wins. Their
                      customer service is A+ and has never left me with an
                      unanswered question.
                    </div>
                    <br />

                    <div className="stars">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                  <div className="form_area login_area">
                    <div className="quats">
                      <img
                        src={
                          require("../assets/images/icons/quats.png").default
                        }
                      />
                    </div>
                    <div className="paragraph white uppercase open_sans">
                      Unsolicited testimonial from Oklahoma USA
                    </div>

                    <div className="form_title small">Jason M.</div>
                    <br />
                    <div className="indicatior in_review"></div>
                    <br />
                    <div className="paragraph white">
                      Thank you for all the support through customer service.
                      Very helpful and patient with me.
                    </div>
                    <br />

                    <div className="stars">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                </Slider>
                <br />
                {/* <div className="pagination">
                  <i class="fas fa-arrow-left"></i>
                  <i class="fas fa-circle"></i>
                  <i class="fas fa-circle"></i>
                  <i class="fas fa-circle"></i>
                  <i class="fas fa-circle"></i>
                  <i class="fas fa-circle"></i>

                  <i class="fas fa-arrow-right"></i>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
