import React, { useState, useEffect } from "react";
// import { liveChatUrl } from "../configs/config.js";
import config from "../configs/config.js";
import api from "../services/api";
import { Modal, Alert, Accordion, Button } from "react-bootstrap";

const SuppotrSideBar = (props) => {
  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const [liveChatUrl, setLiveChatUrl] = useState(config.liveChatUrl);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeSideBarTab, setActiveSideBarTab] = useState("faqTab");

  const onSetSidebarOpen = (ev) => {
    if (sidebarOpen == true) {
      setSidebarOpen(false);
    } else {
      getFaq();
      setSidebarOpen(true);
    }
  };

  //FAQ functionality START'S here
  const [faqData, setFaqData] = useState([]);
  const getFaq = async () => {
    var response = await api.get("/get-faqs");

    if (response.status === 200 && response.data) {
      setFaqData(response.data);
      // console.log("response.dataresponse.data", response.data);
    }
  };

  useEffect(async () => {
    if (props.openSupportSideBar) {
      getFaq();
      setSidebarOpen(props.openSupportSideBar);
    }
  }, [props.openSupportSideBar]);
  //FAQ functionality END'S here

  //Support Ticket START'S here
  const [supportTicketErrors, setSupportTicketErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [supportTicketData, setSupportTicketData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const updateSupportTicketInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    setSupportTicketData({ ...supportTicketData, [field]: value });
  };

  const saveSupportTicketData = async (ev) => {
    var response = await api.post("/create-ticket", supportTicketData);
    // console.log(response);
    if (response.status == 200) {
      setAlertMsg({
        message: response.data.message,
        variant: "success",
        showAlert: true,
      });
    } else {
      // setAlertMsg({
      //   message: response.data.message,
      //   variant: "success",
      //   showAlert: true,
      // });
      if (response.data.errors) {
        setSupportTicketErrors(response.data.errors);
      } else {
        setAlertMsg({
          message: response.data.message,
          variant: "danger",
          showAlert: true,
        });
      }
    }
    setTimeout(() => {
      setAlertMsg({
        showAlert: false,
      });
    }, 5000);
  };

  return (
    <>
      <div className="support-overlay  d-none"></div>
      {sidebarOpen && (
        <div
          className="invisible_overlay"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
      <div
        className="support-panel "
        style={{ right: sidebarOpen ? "0" : "" }}
        id="autoprocessed"
      >
        <div className="winners-tabs">
          <div className="support-panel-header">
            <h3>Support</h3>
            <a
              className="closeSupportBtn"
              onClick={onSetSidebarOpen}
              href="javascript:"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
            <div className="winners-tabs-hds">
              <a
                href="javascript:"
                onClick={() => {
                  setActiveSideBarTab("faqTab");
                }}
                className={
                  activeSideBarTab == "faqTab" ? "winners-tab-active" : ""
                }
              >
                <span>
                  <i className="m-icon-question"></i>FAQ
                </span>
              </a>
              <a
                href="javascript:"
                onClick={() => {
                  setActiveSideBarTab("emailUsTab");
                }}
                className={
                  activeSideBarTab == "emailUsTab" ? "winners-tab-active" : ""
                }
              >
                <span>
                  <i className="m-icon-email"></i> Email Us
                </span>
              </a>
              <a
                href="javascript:"
                onClick={() => {
                  setActiveSideBarTab("liveChatTab");
                }}
                className={
                  activeSideBarTab == "liveChatTab" ? "winners-tab-active" : ""
                }
              >
                <span>
                  <i className="m-icon-call"></i> Live Chat
                </span>
              </a>
            </div>
          </div>
          <div className="winners-tabs-contents">
            <div
              id="support-call-us"
              className={
                "winners-tab-content chat_container " +
                (activeSideBarTab == "liveChatTab" ? "winner-tab-active" : "")
              }
            >
              <iframe src={liveChatUrl}></iframe>
            </div>
            
            <div
              id="support-faq"
              class={
                "winners-tab-content " +
                (activeSideBarTab == "faqTab" ? "winner-tab-active" : "")
              }
            >
              {faqData.length > 0 && (
                <>
                  <Accordion className="parent_accordion">
                    {faqData.map((value, key) => (
                      <Accordion.Item eventKey={key}>
                        <Accordion.Header>{value.category}</Accordion.Header>
                        <Accordion.Body>
                          <Accordion>
                            {value.faqs.map((value2, key2) => (
                              <Accordion.Item eventKey={"faq_" + key + key2}>
                                <Accordion.Header className="child_accordion_header">
                                  {value2.title}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {value2.description}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </>
              )}
            </div>
            <div
              id="support-email-us"
              class={
                "winners-tab-content support_container " +
                (activeSideBarTab == "emailUsTab" ? "winner-tab-active" : "")
              }
            >
              <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                {alertMsg.message}
              </Alert>
              <div className="quick_contact_us">
                <div className="support_content">
                  <div className="contact_us_area register">
                    <div className="form_area login_area support_sideb">
                      <div className="form_title small">Any Question?</div>
                      <br />
                      <div className="pragraph white">
                        You can Live Chat with out Support Team.
                        <br />
                        Click on the Live Support tab when openening the support
                        panel. Feel free to ask them any question you have
                        regarding Gambling Ape, your account or (Live) games on
                        the website.
                        <br />
                        <br />
                        If you want to send us a message through our contact
                        form then you can send it from here.
                      </div>
                      <br />
                      <div className="indicatior"></div>
                      <br />
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="input_item">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={supportTicketData.name}
                              onChange={updateSupportTicketInput}
                              className={
                                "form-control name_value " +
                                (supportTicketErrors.name ? "error" : "")
                              }
                            />
                            <div className="errorMsg">
                              {supportTicketErrors.name}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="input_item">
                            <input
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              value={supportTicketData.phone}
                              onChange={updateSupportTicketInput}
                              className={
                                "form-control name_value " +
                                (supportTicketErrors.phone ? "error" : "")
                              }
                            />
                            <div className="errorMsg">
                              {supportTicketErrors.phone}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="input_item">
                            <input
                              placeholder="Email"
                              type="text"
                              name="email"
                              value={supportTicketData.email}
                              onChange={updateSupportTicketInput}
                              className={
                                "form-control name_value " +
                                (supportTicketErrors.email ? "error" : "")
                              }
                            />
                            <div className="errorMsg">
                              {supportTicketErrors.email}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12">
                          <div className="input_item">
                            <input
                              placeholder="Subject"
                              type="text"
                              name="subject"
                              value={supportTicketData.subject}
                              onChange={updateSupportTicketInput}
                              className={
                                "form-control name_value " +
                                (supportTicketErrors.subject ? "error" : "")
                              }
                            />
                            <div className="errorMsg">
                              {supportTicketErrors.subject}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="input_item">
                            <textarea
                              placeholder="Message"
                              name="message"
                              value={supportTicketData.message}
                              onChange={updateSupportTicketInput}
                              className={
                                "form-control name_value" +
                                (supportTicketErrors.message ? "error" : "")
                              }
                            ></textarea>
                            <div className="errorMsg">
                              {supportTicketErrors.message}
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        type="hidden"
                        name="g-recaptcha-response"
                        value=""
                      />
                      <div className="login_button">
                        <Button
                          onClick={saveSupportTicketData}
                          className="login_btn pad_bottom_40 gradiant"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="collapse-support"
          onClick={onSetSidebarOpen}
          style={{ right: sidebarOpen ? "435px" : "" }}
        >
          <i className="far fa-times-circle"></i>
        </div>
      </div>

      <a
        href="javascript:"
        onClick={onSetSidebarOpen}
        className="support-btn desktopSupportBtn"
      >
        <i className="far fa-question-circle"></i>
      </a>
      <a
        href="javascript:"
        onClick={onSetSidebarOpen}
        className="support-btn mobileSupportBtn"
      >
        <i className="far fa-question-circle"></i>
      </a>
    </>
  );
};

export default SuppotrSideBar;
