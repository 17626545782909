import { createSlice } from "@reduxjs/toolkit";

export const userBalance = createSlice({
  name: "userBalance",
  initialState: {
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
    selectedBalance: "",
  },
  reducers: {
    updateUserBalance: (state, action) => {
      state.cash_balance = action.payload.cash_balance;
      state.bonus_balance = action.payload.bonus_balance;
      state.total_balance = action.payload.total_balance;
      state.wallets = action.payload.wallets;
      state.rates = action.payload.rates;
      state.selectedBalance = action.payload.selectedBalance;
    },
    setDefaultCurrency: (state, action) => {
      state.selectedBalance = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserBalance, setDefaultCurrency } = userBalance.actions;

export default userBalance.reducer;
