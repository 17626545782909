import React from "react";

const SelectBox = (props) => {
  var newProps = {
    ...props,
    onChange: (ev) => {
      if (props.onChange) {
        props.onChange(ev.target.value, ev);
      }
    },
  };

  return (
    <select {...newProps}>
      {newProps.items && newProps.items.length && (
        <>
          {newProps.items.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </>
      )}
    </select>
  );
};

export default SelectBox;
